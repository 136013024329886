exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cgv-tsx": () => import("./../../../src/pages/cgv.tsx" /* webpackChunkName: "component---src-pages-cgv-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-en-about-tsx": () => import("./../../../src/pages/en/about.tsx" /* webpackChunkName: "component---src-pages-en-about-tsx" */),
  "component---src-pages-en-cgv-tsx": () => import("./../../../src/pages/en/cgv.tsx" /* webpackChunkName: "component---src-pages-en-cgv-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-fugu-classic-tsx": () => import("./../../../src/pages/en/fugu-classic.tsx" /* webpackChunkName: "component---src-pages-en-fugu-classic-tsx" */),
  "component---src-pages-en-fugu-mini-tsx": () => import("./../../../src/pages/en/fugu-mini.tsx" /* webpackChunkName: "component---src-pages-en-fugu-mini-tsx" */),
  "component---src-pages-en-fugu-saxo-tsx": () => import("./../../../src/pages/en/fugu-saxo.tsx" /* webpackChunkName: "component---src-pages-en-fugu-saxo-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-fugu-classic-tsx": () => import("./../../../src/pages/fugu-classic.tsx" /* webpackChunkName: "component---src-pages-fugu-classic-tsx" */),
  "component---src-pages-fugu-mini-tsx": () => import("./../../../src/pages/fugu-mini.tsx" /* webpackChunkName: "component---src-pages-fugu-mini-tsx" */),
  "component---src-pages-fugu-saxo-tsx": () => import("./../../../src/pages/fugu-saxo.tsx" /* webpackChunkName: "component---src-pages-fugu-saxo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-it-about-tsx": () => import("./../../../src/pages/it/about.tsx" /* webpackChunkName: "component---src-pages-it-about-tsx" */),
  "component---src-pages-it-cgv-tsx": () => import("./../../../src/pages/it/cgv.tsx" /* webpackChunkName: "component---src-pages-it-cgv-tsx" */),
  "component---src-pages-it-contact-tsx": () => import("./../../../src/pages/it/contact.tsx" /* webpackChunkName: "component---src-pages-it-contact-tsx" */),
  "component---src-pages-it-fugu-classic-tsx": () => import("./../../../src/pages/it/fugu-classic.tsx" /* webpackChunkName: "component---src-pages-it-fugu-classic-tsx" */),
  "component---src-pages-it-fugu-mini-tsx": () => import("./../../../src/pages/it/fugu-mini.tsx" /* webpackChunkName: "component---src-pages-it-fugu-mini-tsx" */),
  "component---src-pages-it-fugu-saxo-tsx": () => import("./../../../src/pages/it/fugu-saxo.tsx" /* webpackChunkName: "component---src-pages-it-fugu-saxo-tsx" */),
  "component---src-pages-it-index-tsx": () => import("./../../../src/pages/it/index.tsx" /* webpackChunkName: "component---src-pages-it-index-tsx" */),
  "component---src-pages-simulator-tsx": () => import("./../../../src/pages/simulator.tsx" /* webpackChunkName: "component---src-pages-simulator-tsx" */)
}

